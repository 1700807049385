import { render, staticRenderFns } from "./ConfigurationDetails.vue?vue&type=template&id=239cc231&scoped=true"
import script from "./ConfigurationDetails.vue?vue&type=script&lang=js"
export * from "./ConfigurationDetails.vue?vue&type=script&lang=js"
import style0 from "./ConfigurationDetails.vue?vue&type=style&index=0&id=239cc231&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "239cc231",
  null
  
)

export default component.exports